<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: Caso não encontre o usuário -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Usuário não encontrado</h4>
    </b-alert>
    <b-tabs v-if="userData" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ userData.name }}</span>
        </template>
        <user-edit-tab-account :user-data="userData" class="mt-2 pt-75" />
      </b-tab>
      <!-- Tab: Permissões -->
      <b-tab>
        <template #title>
          <feather-icon icon="LockIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Permissões</span>
        </template>
        <User-permission class="mt-2 pt-75" :userData="userData"  @saveUserData="saveUserData"/>
      </b-tab>
      <!-- Tab: PRODUTOS -->
      <b-tab>
        <template #title>
          <feather-icon icon="GridIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Produtos</span>
        </template>
        <User-product class="mt-2 pt-75" :userData="userData" @saveUserData="saveUserData"/>
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BCard, BAlert, BLink, BTabs, BTab } from "bootstrap-vue";

import UserEditTabAccount from "./EditTab.vue";
import UserPermission from "./UserPermission.vue"
import UserProduct from "./UserProduct.vue"

import axios from 'axios'
import { baseApiUrl } from '@/global'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BTabs,
    BTab,

    UserEditTabAccount,
    UserPermission,
    UserProduct
  },
  data() {
    return {
      userData: {},
    };
  },
  methods: {
    getUser() {
      axios.get(`${baseApiUrl}/users/${this.$route.params.id}`)
        .then(res => {
          if(res.data.success) {
            this.userData = res.data.data
          } else {
            this.userData = undefined
          }
        })
    },
    saveUserData() {
      axios.post(`${baseApiUrl}/users/${this.userData._id}`, this.userData)
        .then(res => {
          if(res.data.success){
            this.$bvToast.toast("Atualização feita com sucesso!", {
                title: "Sucesso!",
                variant: "primary",
                solid: true,
              });
          }
        })
    }
  },
  created() {
    this.getUser();
  },
};
</script>

<style>
</style>