<template>
  <b-card>
    <b-form>
      <b-row>
        <!--Nome   -->
        <b-col cols="12" md="4">
          <b-form-group label="Nome" label-for="name">
            <b-form-input id="name" v-model="userData.name" :disabled="!edit">
            </b-form-input>
          </b-form-group>
        </b-col>
        <!--E-mail   -->
        <b-col cols="12" md="4">
          <b-form-group label="E-mail" label-for="email">
            <b-form-input id="email" v-model="userData.email" :disabled="!edit">
            </b-form-input>
          </b-form-group>
        </b-col>
        <!--Telefone   -->
        <b-col cols="12" md="4">
          <b-form-group label="Telefone" label-for="phone">
            <b-form-input id="phone" v-model="userData.phone" :disabled="!edit">
            </b-form-input>
          </b-form-group>
        </b-col>
        <!--Acesso   -->
        <b-col cols="12" md="4">
          <b-form-group label="Acesso" label-for="access">
            <v-select
              v-model="userData.access"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="accessOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="access"
              :disabled="!edit"
            />
          </b-form-group>
        </b-col>
        <!--Botões   -->
        <b-col cols="12" md="4">
          <b-form-group>
            <div v-if="!edit">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="btn-icon mt-2"
                @click="edit = !edit"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
            </div>
            <div v-else>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mt-2 ml-1"
                @click="save()"
              >
                <feather-icon icon="SaveIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-icon mt-2 ml-1"
                @click="reset()"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

import axios from "axios";
import { baseApiUrl } from "@/global";

export default {
  props: {
    userData: {
      type: Object
    },
  },
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
  },
  data() {
    return {
      accessOptions: [],
      edit: false,
      newUserData: {...this.userData},
    };
  },
  methods: {
    getAccess() {
      this.accessOptions = [
        { label: "Administrador", value: "Administrador" },
        { label: "Staff", value: "Staff" },
      ];
    },
    save() {
      this.edit = false;
      axios
        .post(`${baseApiUrl}/users/${this.userData._id}`, this.userData)
        .then((res) => {
          if(!res.data.success) return
          this.$bvToast.toast("Atualização feita com sucesso!", {
            title: "Sucesso!",
            variant: "primary",
            solid: true,
          });
        });
    },
    reset() {
      this.edit = false;
    }
  },
  created() {
    this.getAccess()
    
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>