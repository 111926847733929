<template>
  <b-row>
    <b-col cols="12">
      <b-card-code>
        <!-- table -->
        <vue-good-table :columns="columns" :rows="menus" :rtl="direction">
          <!-- Slot: Table Row -->
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field == 'status'" class="text-nowrap">
              <span>
                <b-form-checkbox
                  :checked="checkAccess(props.row.id)"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  @change="edit(props.row.id)"
                />
              </span>
            </span>
          </template>
        </vue-good-table>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { VueGoodTable } from "vue-good-table";

import store from "@/store/index";
import menu from "../../../navigation/vertical/index.js";

import axios from "axios";
import { baseApiUrl } from "@/global";

export default {
  props: {
    userData: {
      type: Object,
    },
  },
  components: {
    BRow,
    BCol,
    BCardCode,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    BFormSelect,
    BPagination,
    BButton,
    BFormCheckbox,
  },
  data() {
    return {
      searchTerm: "",
      pageLength: 10,
      columns: [
        {
          label: "Acesso",
          field: "title",
        },
        {
          label: "Status",
          field: "status",
        },
      ],
      menus: menu,
    };
  },
  methods: {
    checkAccess(access) {
      if (!this.userData.menu) return;
      function permision(menu) {
        return menu == access;
      }
      return this.userData.menu.some(permision);
    },
    edit(id) {
      function findAcess(access) {
        return access == id;
      }
      if (this.userData.menu.some(findAcess)) {
        this.userData.menu.splice(this.userData.menu.indexOf(id.toString()), 1);
      } else {
        this.userData.menu.push(id.toString());
      }
      this.$emit('saveUserData')
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },
  created() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
.clique:hover {
  cursor: pointer;
  transform: scale(1.5);
  transition: all 0.5s;
}
</style>